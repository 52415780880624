<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="10">
        <v-card-title>Agrega una cita</v-card-title>
        <v-text-field
          v-model="asunto"
          hide-details
          prepend-icon="message"
          class="ma-3"
          label="Asunto"
        />
        <v-text-field
          v-model="numcont"
          hide-details
          prepend-icon="person"
          class="ma-3"
          label="Contacto"
        />
        <v-text-field
          v-model="empresa"
          hide-details
          prepend-icon="work"
          class="ma-3"
          label="Empresa"
          readonly
        />
        <v-text-field
          v-model="lugar"
          hide-details
          class="ma-3"
          label="Lugar"
          prepend-icon="place"
        />
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          safely
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-select
              v-model="seledura"
              hide-details
              class="ma-3"
              prepend-icon="timer"
              label="Duración (min)"
              :items="duracion"
            />
            <v-text-field
              v-model="fecha"
              hide-details
              class="ma-3"
              label="Fecha"
              persistent-hint
              prepend-icon="event"
              min="2021-05-24"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="fecha"
            no-title
            locale="es"
            @input="menu1 = false"
          />
        </v-menu>
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="hora"

          max-width="290px"
          min-width="290px"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="hora"
              hide-details
              class="ma-3"
              label="Hora de la cita"
              prepend-icon="access_time"
              readonly
              v-on="on"
            />
            <v-text-field
              v-model="numproy"
              hide-details
              prepend-icon="notes"
              class="ma-3"
              label="Proyecto"
            />
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="time"
            full-width
            @click:minute="$refs.menu.save(time)"
          />
        </v-menu>
        <v-textarea
          v-model="temas"
          class="ma-3"
          outlined
          name="input-7-4"
          label="Temas a tratar"
          rows="3"
          auto-grow
        />
        <v-row justify="center">
          <v-btn
            align="right"
            color="green"
            dark
            class="ma-5"
            :to="{ name: '' }"
          >
            Guardar cita
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      duracion: ['30','60','90','120'],
      seledura: '30',
      snackbar: false,
      time: '10:30',
      time2:null,
      detalle:'',
      titulo:'',
      menu2: false,
		    menu3: false,
      fecha: new Date().toISOString().substr(0, 10),
      date1: new Date().toISOString().substr(0, 10),
		    menu1: false,
		    minutos:30,
		    asunto:'',
		    numcont:'',
		    empresa:'',
		    lugar:'',
		    hora:'',
		    temas:'',
		    numproy:'',
    }
  },
  created(){
    console.log('fecha',this.date)
  },
  methods:{
    ...mapActions('citas',['addCita']),

    validaInfo(){
      if(this.asunto == '' || this.contacto == '' || this.empresa == '' || this.lugar == '' || this.seledura == ''
					 || this.fecha == '' || this.hora == ''|| this.numproy == ''|| this.temas == ''){
        this.msg  = 'Por favor no dejes campos vacios'
        this.snackbar = true
      }else{
        this.agregar()
      }
    },

    agregar(){
      var payload = {
        asunto:   this.asunto,
        contacto:  this.contacto,
        empresa:  this.empresa,

        lugar: this.lugar,
        seledura:  this.seledura,

        fecha:    this.fecha,
        numproy:  this.numproy,
        temas: this.temas,
        open:     '0'
      }
      this.addCita(payload)
    }
  }
}
</script>